var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"alert alert-custom alert-notice alert-light-warning fade show m-0",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-3x svg-icon-warning mr-3"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Info-circle.svg"}})],1)]),_c('div',{staticClass:"alert-text text-justify font-weight-bold"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'You can ask an update by <strong>making an update request</strong>.'
          )
        )}}),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'If the seller accept it the <strong>deadline will be reset</strong>.'
          )
        )}})])]),_c('div',{staticClass:"card card-custom shadow-sm mb-5 mt-5"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("Make an update request"))+" ")])])]),_c('div',{staticClass:"card-body pt-2"},[_c('form',{staticClass:"form",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-12 col-form-label font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Title"))+" ")]),_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"required":"","state":_vm.validateState('title'),"type":"text","placeholder":_vm.$t('Title'),"autocomplete":"off"},model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},_vm._l((_vm.errorMessages('title')),function(message){return _c('p',{key:message},[_vm._v(" "+_vm._s(message)+" ")])}),0)],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-12 col-form-label font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Content"))+" ")]),_c('div',{staticStyle:{"height":"200px"},attrs:{"id":"description"},domProps:{"innerHTML":_vm._s(_vm.contentHtml)}})]),_c('br'),_c('div',{staticClass:"col-sm-12 text-center"},[_c('button',{staticClass:"col-sm-6 btn btn-light-warning btn-lg font-weight-bolder",attrs:{"id":"btn_submit"}},[_vm._v(" "+_vm._s(_vm.$t("Submit"))+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }